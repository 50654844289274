import { VApp } from 'vuetify/lib/components/VApp';
import { VContent } from 'vuetify/lib/components/VContent';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VApp,
    [
      _c(VContent, [
        _c("div", { staticClass: "page-container" }, [
          _c(
            "div",
            { staticClass: "page-content" },
            [
              _c(VProgressCircular, {
                attrs: { indeterminate: "", size: "100", color: "#fff" },
              }),
              _c("div", { staticClass: "page-content-container" }, [
                _c("h2", { staticClass: "page-content-title" }, [
                  _vm._v(_vm._s(_vm.$t("please_wait"))),
                ]),
                _c("p", { staticClass: "page-content-subtitle" }, [
                  _vm._v(" " + _vm._s(_vm.$t("redirect_other_platform")) + " "),
                ]),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }